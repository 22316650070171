import React, { useState } from 'react';

export function InputText({ label, type, placeholder, style, inputStyle }) {
    return (
        <div className='input-text' style={style}>
            {
                label !== null && <label className='input-text__label'>{label}</label>
            }
            <input className='input-text__input' type={type} placeholder={placeholder} style={inputStyle} />
        </div>
    )
}

export function InputTextOutlineVertical({ label, type, value, placeholder, style, inputStyle }) {
    return (
        <div className='input-text-vertical' style={style}>
            {
                label !== null && <label className='input-text-vertical__label'>{label}</label>
            }
            <input onChange={()=>{}} className='input-text-vertical__input' type={type} value={value} placeholder={placeholder} style={inputStyle} />
        </div>
    )
}

export function InputSelect({ value, list, style }) {
    return (
        <select className='input-select' style={style}>
            {
                list.map((option) => {
                    return <option>{option}</option>
                })
            }
        </select>
    )
}


export function FilterSelect({ label, value, list, style }) {
    return (
        <div className='filter-select' style={style}>
            <label className='filter-select__label'>{label}</label>
            <select className='filter-select__select'>
                {
                    list.map((option) => {
                        return <option>{option}</option>
                    })
                }
            </select>
        </div>
    )
}

export function InputSearch({ value, placeholder, style }) {
    return (
        <div className='input-search' style={style}>
            <img src='./../icons/search-normal.svg' alt='icon' style={{ marginRight: '.8rem' }} />
            <input className='input-search__input' value={value} placeholder={placeholder} />
        </div>
    )
}

export function Checkbox({ isSelected, style }) {
    const [isChecked, setIsChecked] = useState(isSelected);
    return (
        <button className='custom-checkbox' onClick={() => { setIsChecked(!isChecked) }}>
            {
                isChecked === true
                    ?
                    <img src='./../icons/checkbox-on.svg' alt='icon' />
                    :
                    <img src='./../icons/checkbox-off.svg' alt='icon' />
            }
        </button>
    )
}