import React from 'react';
import { useNavigate } from "react-router-dom";
import { LoginHeader } from '../components/Headers';
import { InputText } from '../components/Inputs';
import { PrimaryBtn, PrimaryTextBtn } from '../components/Buttons';

function LoginPage() {
    let navigate = useNavigate();

    return (
        <section className='login-container'>
            <LoginHeader />

            <div className='login-container__content'>
                <div className='login-box'>
                    <h2 className='login-box__title'>Anmelden</h2>

                    <p className='login-box__desc'>Melden Sie sich an, um die Wunschplanung zu verwalten</p>


                    <InputText
                        label='Benutzername'
                        placeholder='Benutzernamen hier eingeben'
                        type='text'
                        style={{ maxWidth: 262, marginBottom: 42 }}
                    />

                    <InputText
                        label='Passwort'
                        placeholder='Passwort hier eingeben'
                        type='password'
                        style={{ maxWidth: 262, marginBottom: 28 }}
                    />

                    <PrimaryBtn label='Login' onClick={() => { navigate('/wunschplanung') }} style={{ maxWidth: 320 }} />

                    <PrimaryTextBtn label='Passwort vergessen?' style={{ marginTop: 28 }} />

                </div>
            </div>
        </section>
    )
}

export default LoginPage;
