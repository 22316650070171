import React from 'react';

export function TimeRangePicker({ startTime, endTime, onTimeChange }) {
    const handleStartTimeChange = (event) => {
        onTimeChange({ startTime: event.target.value, endTime });
    };

    const handleEndTimeChange = (event) => {
        onTimeChange({ startTime, endTime: event.target.value });
    };


    return (
        <div>
            <label>
                Start:
                <input
                    type="time"
                    className='time-range-picker-input-field'
                    value={startTime}
                    onChange={handleStartTimeChange}
                />
            </label>
            <label>
                Ende:
                <input
                    type="time"
                    className='time-range-picker-input-field'
                    value={endTime}
                    onChange={handleEndTimeChange}
                />
            </label>
        </div>
    );
};