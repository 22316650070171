const BASE_URL = 'https://my-decision-api-9869f5a91744.herokuapp.com'; // http://172.20.10.3:3001 || https://9eef-37-201-46-101.ngrok-free.app || https://my-decision-api-9869f5a91744.herokuapp.com

export const getApsEmployeesByWeekdayApi = async (weekday, selectedDate, groupId) => {
    const res = await fetch(
        `${BASE_URL}/apsEmployeesByWeekdayAndDateAndGroupId?day=${weekday}&date=${selectedDate}&groupId=${groupId}`
    );
    return await res.json();
};

export const getApsEmployeesByYearApi = async (year, groupId) => {
    const res = await fetch(
        `${BASE_URL}/apsEmployeesByYearAndGroupId?year=${year}&groupId=${groupId}`
    );
    return await res.json();
};


export const getEmployeesCommitmentsByDateAndGroupIdApi = async (selectedDate, groupId) => {
    const res = await fetch(
        `${BASE_URL}/user-work-commitmentsByDateAndGroupId?date=${selectedDate}&groupId=${groupId}`
    );
    return await res.json();
};

export const getNotReportedEmployeesByDateAndGroupIdApi = async (selectedDate, groupId) => {
    const res = await fetch(
        `${BASE_URL}/apsNotReportedEmployeesByDateAndGroupId?date=${selectedDate}&groupId=${groupId}`
    );
    return await res.json();
};

export const adjustUserWorkCommitmentStatusByIdApi = async (workCommitmentId, status) => {
    const url = `${BASE_URL}/adjustUserWorkCommitmentStatusById?workCommitmentId=${workCommitmentId}&status=${status}`;
    const options = {
        method: 'PATCH'
    };

    const res = await fetch(url, options);
    return await res.json();
};

export const sendCounterproposalByIdApi = async (workCommitmentId, startTime, endTime, status, couUserId) => {
    const url = `${BASE_URL}/sendCounterproposalById?workCommitmentId=${workCommitmentId}&startTime=${startTime}&endTime=${endTime}&status=${status}&couUserId=${couUserId}`;
    const options = {
        method: 'PATCH'
    };

    const res = await fetch(url, options);
    return await res.json();
};

export const getGroupsApi = async () => {
    const res = await fetch(
        `${BASE_URL}/groups`
    );
    return await res.json();
};
