import React, { useState, useEffect } from 'react';
import { SideNav } from '../components/SideNav';
import { PrimarySmBtn, PrimaryBtn } from '../components/Buttons';
import { SelectModal } from '../components/Modals';
import { Employee } from '../components/Employee';
import { Checkbox, FilterSelect, InputSearch, InputSelect } from '../components/Inputs';


function MitarbeiterPage() {
    const [isShowGruppeAnlegenModal, setIsShowGruppeAnlegenModal] = useState(false);
    const [isShowMitarbeiterHinzufügenModal, setIsShoMitarbeiterHinzufügenModal] = useState(false);
    const [isShowÜberstundenAngebenModal, setIsShowÜberstundenAngebenModal] = useState(false);

    const [employees, setEmployees] = useState(null)

    useEffect(() => {
        fetch('http://localhost:3001/users')
            .then(res => {
                return res.json();
            })
            .then(data => {
                console.log(data);
                setEmployees(data);
            })
    }, [])

    console.log("fdsa1", employees);



    return (
        <div className='dashboard'>
            <SideNav />

            <section className='wunschplan-cont' style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2.4rem' }}>
                <h2 className='title' style={{ marginBottom: '1.6rem' }}>Mitarbeiter Verwaltung</h2>
                <h2 className='sub-title' style={{ marginBottom: '4rem' }}>Hier können Sie Ihre Mitarbeiter verwalten</h2>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '6.4rem' }}>
                    <FilterSelect
                        label='Filter:'
                        list={['Alle Gruppen', 'Alle Gruppen', 'Alle Gruppen']}
                        style={{ width: '25rem' }}
                    />
                    <div style={{ flexGrow: 1 }}></div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PrimaryBtn label='Gruppe anlegen' onClick={() => { setIsShowGruppeAnlegenModal(true) }} style={{ width: '16.5rem' }} />
                        <PrimaryBtn label='Mitarbeiter hinzufügen' onClick={() => { setIsShoMitarbeiterHinzufügenModal(true) }} style={{ width: '25.5rem', marginLeft: '1.4rem' }} />
                        <PrimaryBtn label='Überstunden angeben' onClick={() => { setIsShowÜberstundenAngebenModal(true) }} style={{ width: '25.5rem', marginLeft: '1.4rem' }} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.6rem' }}>
                    <InputSearch placeholder='Mitarbeiter suchen …….' />

                    <InputSelect list={['Action']} style={{ width: '15rem', marginLeft: '2rem' }} />
                </div>

                <div className='scroll-sm' style={{ flexGrow: 1, overflow: 'auto' }}>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th><Checkbox isSelected={false} /></th>
                                <th>Nachname</th>
                                <th>Vorname</th>
                                <th>Gruppe</th>
                                <th>WAZ-Stunden</th>
                                <th>Soll-Stunden</th>
                                <th>Ist-Stunden</th>
                            </tr>
                        </thead>

                        {employees && <Employee key={employees} employees={employees} />}
                    </table>
                </div>
            </section>

            {/* <SelectModal showModal={isShowGruppeAnlegenModal} style={{ width: '68rem', height: '74rem', padding: '2.4rem' }}>
                <h4 className='select-month-year-modal__title'>Gruppe anlegen</h4>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '4.8rem', marginBottom: '2.6rem' }}>
                    <input type='text' className='input-text-plain' placeholder='Gruppennamen' style={{ width: '18rem', marginRight: '1.6rem' }} />

                    <select className='input-select' disabled style={{ width: '18rem', height: '4rem', marginRight: '1.6rem' }}>
                        <option>Tarifgruppe</option>
                        <option>Tarifgruppe</option>
                        <option>Tarifgruppe</option>
                    </select>

                    <select className='input-select' style={{ width: '15rem', height: '4rem' }}>
                        <option>Montag</option>
                        <option>Montag</option>
                        <option>Montag</option>
                    </select>
                </div>

                <p className='select-month-year-modal__sub-title'>Anwesenheitsplan angeben</p>

                <div className='modal-roster-list'>
                    <div className='modal-roster-list__title'>
                        <p className='label'> </p>
                        <p className='label'>Von</p>
                        <p className='label'>Bis</p>
                    </div>

                    <div style={{ display: 'flex', flexGrow: 1, overflow: 'auto', paddingBottom: '1.2rem' }}>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                    </div>

                    <PrimarySmBtn
                        label='AP +'
                        onClick={() => { }}
                        style={{ width: '7.5rem', marginLeft: '1.8rem' }}
                    />
                </div>

                <p className='select-month-year-modal__sub-title'>Soll MA angeben</p>

                <div className='modal-roster-list'>
                    <div className='modal-roster-list__title'>
                        <p className='label'> </p>
                        <p className='label'>Von</p>
                        <p className='label'>Bis</p>
                    </div>

                    <div style={{ display: 'flex', flexGrow: 1, overflow: 'auto', paddingBottom: '1.2rem' }}>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>

                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                        <div className='modal-roster-list__item'>
                            <div className='number label'>AP1</div>
                            <div className='number'>07:30</div>
                            <div className='number'>11:30</div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PrimarySmBtn
                        label='Hinzufügen'
                        onClick={() => { setIsShowGruppeAnlegenModal(false) }}
                        style={{ width: '12.7rem', marginRight: '2rem' }}
                    />

                    <PrimarySmBtn
                        label='Abbrechen'
                        onClick={() => { setIsShowGruppeAnlegenModal(false) }}
                        style={{ width: '12.7rem' }}
                    />
                </div>
            </SelectModal > */}

            <SelectModal showModal={isShowMitarbeiterHinzufügenModal} style={{ width: '62rem', height: '34rem', padding: '2.4rem' }}>
                <h4 className='select-month-year-modal__title'>Mitarbeiter hinzufügen</h4>

                <div style={{ display: 'flex', marginTop: '3.2rem' }}>
                    <input type='text' className='input-text-plain' placeholder='Nachname' style={{ width: '25rem', marginRight: '2.8rem' }} />
                    <input type='text' className='input-text-plain' placeholder='Vorname' style={{ width: '25rem' }} />
                </div>

                <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <select className='input-select' style={{ width: '25rem', height: '4rem', marginRight: '2.8rem' }}>
                        <option>Gruppe</option>
                        <option>Gruppe</option>
                        <option>Gruppe</option>
                    </select>

                    <input type='text' className='input-text-plain' placeholder='Soll-Stunden' style={{ width: '25rem' }} />
                </div>

                <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <input type='text' className='input-text-plain' placeholder='Arbeitstage' style={{ width: '25rem' }} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3.2rem' }}>
                    <PrimarySmBtn
                        label='Hinzufügen'
                        onClick={() => { setIsShoMitarbeiterHinzufügenModal(false) }}
                        style={{ width: '12.7rem', marginRight: '2rem' }}
                    />

                    <PrimarySmBtn
                        label='Abbrechen'
                        onClick={() => { setIsShoMitarbeiterHinzufügenModal(false) }}
                        style={{ width: '12.7rem' }}
                    />
                </div>
            </SelectModal>

            <SelectModal showModal={isShowÜberstundenAngebenModal} style={{ width: '47.5rem', height: '40rem', padding: '2.4rem' }}>
                <h4 className='select-month-year-modal__title'>Überstunden angeben</h4>
                <h4 className='select-month-year-modal__title' style={{ fontSize: '2.4rem' }}>Marie Meier</h4>

                <div className='modal-roster-list' style={{ width: 'auto', marginTop: '2.4rem', marginBottom: 0 }}>
                    <div className='modal-roster-list__title' style={{ width: '14rem', height: '18rem', padding: '2rem 1.2rem', alignItems: 'flex-end' }}>
                        <p className='label'>Von</p>
                        <p className='label'>Von</p>
                        <p className='label'>Bis</p>
                        <p className='label'>Überstunden Summe</p>
                    </div>

                    <div className='modal-roster-list__item' style={{ width: '11rem', height: '18rem', padding: '1rem' }}>
                        <div className='number' style={{ width: '9rem', fontWeight: '400' }}>13.04.2023</div>
                        <div className='number' style={{ width: '9rem', fontWeight: '400' }}>10:00</div>
                        <div className='number' style={{ width: '9rem', fontWeight: '400' }}>11:30</div>
                        <div className='number' style={{ width: '9rem', fontWeight: '400' }}>01:30 h</div>
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', marginTop: '3.2rem' }}>
                    <PrimarySmBtn
                        label='Hinzufügen'
                        onClick={() => { setIsShowÜberstundenAngebenModal(false) }}
                        style={{ width: '12.7rem', marginRight: '2rem' }}
                    />

                    <PrimarySmBtn
                        label='Abbrechen'
                        onClick={() => { setIsShowÜberstundenAngebenModal(false) }}
                        style={{ width: '12.7rem' }}
                    />
                </div>
            </SelectModal>
        </div >
    )
}

export default MitarbeiterPage;