import React from 'react';

export function PrimaryBtn({ label, style, onClick }) {
    return (
        <button className='primary-btn' style={style} onClick={onClick}>{label}</button>
    )
}

export function PrimaryMdBtn({ label, style, onClick }) {
    return (
        <button className='primary-btn md' style={style} onClick={onClick}>{label}</button>
    )
}

export function PrimarySmBtn({ label, style, onClick }) {
    return (
        <button className='primary-btn sm' style={style} onClick={onClick}>{label}</button>
    )
}

export function PrimaryTextBtn({ label, style, onClick }) {
    return (
        <button className='primary-text-btn' style={style} onClick={onClick}>{label}</button>
    )
}

export function GroupBtn({ title, description, style, onClick }) {
    return (
        <button className='groups__item' style={style} onClick={onClick}>
            <h3 className='title'>{title}</h3>
            <p className='desc'>{description}</p>
        </button>
    )
}