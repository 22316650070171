import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { SideNav } from './SideNav';
import { GroupBtn } from './Buttons';
import { getGroupsApi } from '../api';

function GroupsOverview({ onGroupSelect }) {

    let navigate = useNavigate();
    const [allGroups, setAllGroups] = useState([]);


    const handleClick = (groupNameURL, title, id) => {
        console.log("Group: ", title, id);
        onGroupSelect(title, id);
        const urlPrefix = window.location.pathname.includes('wunschplanung') ? '/wunschplanung' : '/finaleplanung';
        navigate(`${urlPrefix}/${groupNameURL}`);
    };


    useEffect(() => {
        getGroupsApi()
            .then((data) => {
                setAllGroups(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    return (
        <div className='dashboard'>
            <SideNav />

            <section className='wunschplan-cont'>
                <h2 className='title'>Übersicht Gruppen</h2>

                <div className='groups'>
                    {
                        allGroups.map((item, index) => (
                            <GroupBtn
                                key={index}
                                title={item.name}
                                // description='Aktualisiert: 16.03.2022'
                                onClick={() => handleClick(`${item.name.replace(/ /g, "_")}`, `${item.name}`, `${item.id}`)}
                            />
                        ))
                    }
                </div>
            </section>
        </div>
    )
}

export default GroupsOverview;