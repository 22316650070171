import { Checkbox } from './Inputs';


export const Employee = ({ employees }) => {
    console.log("dsd2", employees);
    return (
        <tbody>
            {employees.map(employee => (
                <tr key={employee.id}>
                    <td><Checkbox isSelected={false} /></td>
                    <td>{employee.last_name}</td>
                    <td>{employee.first_name}</td>
                    <td>{employee.group_name}</td>
                    <td>{employee.weekly_working_hours}</td>
                    <td>{employee.must_hours}</td>
                    <td>{employee.workedHoursInSeconds / 60 / 60}</td>
                </tr>
            ))}
        </tbody>

        // { employees.map((employee) => <Employee key={employee.id} lastName={employee.lastName} firstName={employee.firstName} group={employee.group} weeklyWorkingHours={employee.weeklyWorkingHours} haveToWorkingHours={employee.haveToWorkingHours} workedHours={employee.workedHours} />) }

    );
}