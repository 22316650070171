import React from 'react';
import RosterListItem from './RosterListItem';

export default function RosterList({ data }) {
  // Gruppierung der Daten in Gruppen von je 8 Elementen
  const groupedData = [];
  let currentGroup = [];
  data.map(item => {
    if (currentGroup.length === 8) {
      groupedData.push(currentGroup);
      currentGroup = [];
    }
    currentGroup.push(item);
  });
  // Füge die letzte Gruppe hinzu
  if (currentGroup.length > 0) {
    groupedData.push(currentGroup);
  }

  // Erstellung der JSX-Elemente für jede Gruppe
  const rosterListGroups = groupedData.map((group, index) => (
    <div className='roster-list' key={index}>
      <div className='roster-list__title'>
        <p className='label'>Uhrzeit</p>
        <p className='label'>Soll</p>
        <p className='label'>Ist</p>
      </div>
      {group.map((item, index) => (
        <RosterListItem
          key={index}
          hour={item.hour}
          should={item.max}
          actual={item.committed}
          actualColor={item.color}
        />
      ))}
    </div>
  ));

  return <>{rosterListGroups}</>;
}
