import React from 'react';
import { NavLink } from 'react-router-dom';
import { SIDELINKS } from '../constants';

export function SideNav() {
    return (
        <nav className='dashboard__nav'>
            <img className='dashboard__nav--logo' src='./../logo.png' alt='logo' />

            <nav className='dashboard__nav--links'>
                {/* side bar links */}
                {
                    SIDELINKS.map(sideItem => (
                        <NavLink key={sideItem.id} to={sideItem.link} className='link-item'>
                            <img className='icon' src={sideItem.icon} alt='icon' />
                            <img className='icon active' src={sideItem.active} alt='icon' />
                            {sideItem.label}
                        </NavLink>
                    ))
                }
            </nav>

            <NavLink to='/' className='link-item'>
                <button className='dashboard__nav--logout'>
                    <img className='icon' src='./../icons/sign-out.svg' alt='icon' />
                    Abmelden
                </button>
            </NavLink>
        </nav>
    )
}