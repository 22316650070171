import React, { useState, useEffect, useRef } from 'react';

export function CustomCalendar({ calendarType, setIsDateSelected, selectedYear, data, selectedDate, setSelectedDate, externalCalendarDate }) {
    const [popupIndex, setPopupIndex] = useState(null);



    const [previousIndex, setPreviousIndex] = useState(null);
    const refs = useRef({});


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (Array.isArray(data)) {
            setLoading(false);
        }
    }, [data]);



    const handleButtonClick = (index) => {
        setPreviousIndex(index);
        if (previousIndex !== null && index !== previousIndex) {
            document.getElementById(`calandarpopupbg-${previousIndex}`).style.display = 'block';
            document.getElementById(`calandarpopupbg-${index}`).style.display = 'none';
        } else {
            document.getElementById(`calandarpopupbg-${index}`).style.display = 'none';
        }
    };


    useEffect(() => {
        if (externalCalendarDate) {
            setPopupIndex(externalCalendarDate.month)
            setIsDateSelected(true)
            setPreviousIndex(externalCalendarDate.month);
            setSelectedDate({ day: externalCalendarDate.day, month: externalCalendarDate.month, year: externalCalendarDate.year })

            const element = refs.current[`calandarpopupbg-${externalCalendarDate.month}`];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
                element.style.display = 'none';
            }

            if (previousIndex !== null && externalCalendarDate.month !== previousIndex) {
                document.getElementById(`calandarpopupbg-${previousIndex}`).style.display = 'block';
                document.getElementById(`calandarpopupbg-${externalCalendarDate.month}`).style.display = 'none';
            } else {
                console.log('External Opening Initial popup:', externalCalendarDate.month);
                document.getElementById(`calandarpopupbg-${externalCalendarDate.month}`).style.display = 'none';
            }
        }
    }, [externalCalendarDate]);


    const weekDays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];


    const renderMonth = (monthData, monthIndex) => {
        // Initialize an array for each week with empty slots
        const weeks = [];
        let currentWeek = new Array(7).fill(null);

        // Fill the array with days
        monthData.days.forEach((dayData) => {
            const dayIndex = weekDays.indexOf(dayData.weekday);
            currentWeek[dayIndex] = dayData;

            // If the week is complete, push it to the weeks array and start a new week
            if (dayIndex === 6) {
                weeks.push(currentWeek);
                currentWeek = new Array(7).fill(null);
            }
        });

        // Push the last week if it has any days
        if (currentWeek.some(day => day !== null)) {
            weeks.push(currentWeek);
        }

        return (
            <div key={monthData.monthKey} className="calendar__month">
                <div className="calendar__weekdays" style={{ display: 'flex', width: '100%' }}>
                    {weekDays.map((weekday, index) => (
                        <div key={index} className="calendar__cell_weekday" style={{ width: 'calc(100% / 7)', textAlign: 'center' }}>
                            {weekday}
                        </div>
                    ))}
                </div>
                {weeks.map((week, weekIndex) => (
                    <div key={weekIndex} className="calendar__week" style={{ display: 'flex', width: '100%' }}>
                        {week.map((dayData, dayIndex) => (
                            <div
                                key={dayIndex}
                                className="calendar__cell"
                                style={{ width: 'calc(100% / 7)' }}
                                onClick={() => {
                                    if (dayData) {
                                        setPopupIndex(monthData.monthKey);
                                        setIsDateSelected(true);
                                        setSelectedDate({ day: dayData.day, month: monthData.monthKey, year: selectedYear });
                                    }
                                }}
                            >
                                {dayData ? (

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '3.8rem',
                                            height: '5.5rem',
                                            borderRadius: '1rem',
                                            backgroundColor: monthIndex === popupIndex && dayData.day === selectedDate.day && monthIndex === selectedDate.month ? '#3288D2' : '#ffffff',
                                        }}
                                    >
                                        <div className="calendar__cell--icon">
                                            {dayData.status && calendarType === 'wishPlaning' && (
                                                <img
                                                    src={`./../icons/${dayData.status}${dayData.day === selectedDate.day ? '-active' : ''}.svg`}
                                                    alt="icon"
                                                />
                                            )}
                                        </div>
                                        <p
                                            className="calendar__cell--text"
                                            style={{ color: monthIndex === popupIndex && dayData.day === selectedDate.day && monthIndex === selectedDate.month ? 'white' : '#171725', backgroundColor: monthIndex === popupIndex && dayData.day === selectedDate.day && monthIndex === selectedDate.month ? '#3288D2' : '#ffffff' }}
                                        >
                                            {dayData.day}
                                        </p>
                                        <div className={`calendar__cell--status ${dayData.cellColor}`} />
                                    </div>
                                ) : (
                                    <div style={{ height: '5rem' }}></div>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            {loading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                </div>
            ) : (data.map((item, i) => (

                <div className='maincalandar' key={i}>
                    <div id={`calandarpopupbg-${item.monthKey}`} ref={el => refs.current[`calandarpopupbg-${item.monthKey}`] = el} className="calandarpopupbg">
                        <button onClick={() => handleButtonClick(item.monthKey)} className="popupbutton">Monat bearbeiten</button>
                    </div>

                    <div className='calendar'>
                        <div className='calendar__header'>
                            <p className='calendar__header--title'>
                                <span style={{ fontWeight: 600 }}>{item.month}</span> {selectedYear}
                            </p>
                            <div className='calendar__header--line'></div>
                        </div>
                        <div className='calendar__body'>
                            {renderMonth(item, i + 1)}
                        </div>
                    </div>
                </div>
            ))
            )}
        </>
    );
}