import React from 'react';

export function SelectModal({ showModal, children, border, style }) {
    return (
        <div className={`modal-overlay ${showModal === true && 'show-modal'}`}>
            <div className={`select-month-year-modal ${border === true && 'border'}`} style={style}>
                {children}
            </div>
        </div>
    )
}