import React from 'react'

export default function RosterListItem({ hour = "", should = "", actualColor = "", actual = "" }) {
  return (
    <div className='roster-list__item'>
      <div className={`number hour`}>{hour}</div>
      <div className={`number should`}>{should}</div>
      <div className={`number actual`} style={{ backgroundColor: actualColor }}>{actual}</div>
    </div>
  )
}
