export const initialActiveDates = (specifiedYear = null) => {
  const currentDate = new Date(); // aktuelles Datum
  let currentYear = currentDate.getFullYear(); // aktuelles Jahr

  if (specifiedYear !== null) {
    currentYear = specifiedYear; // Verwendung des angegebenen Jahres, falls vorhanden
  }

  const months = {}; // leeres Objekt für die Monate

  // Schleife über die Monate des aktuellen Jahres
  for (let month = 0; month < 12; month++) {
    const monthDate = new Date(currentYear, month, 20); // Datum für den 20. Tag des Monats
    const formattedMonth = `${currentYear}-${(month + 1).toString().padStart(2, '0')}`; // Formatierung des Monats im "YYYY-MM" Format
    months[formattedMonth] = monthDate; // Hinzufügen des Monats zum Objekt
  }

  return months; // Rückgabe des Objekts mit den Monaten
};

export const myCustomLocale = {
  // months list by order
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],

  // week days by order
  weekDays: [
    {
      name: 'Montag',
      short: 'Mo',
    },
    {
      name: 'Dienstag',
      short: 'Di',
    },
    {
      name: 'Mittwoch',
      short: 'Mit',
    },
    {
      name: 'Donnerstag',
      short: 'Do',
    },
    {
      name: 'Freitag',
      short: 'Fr',
    },
    {
      name: 'Samstag',
      short: 'Sa',
    },
    {
      name: 'Sonntag', // used for accessibility 
      short: 'So', // displayed at the top of days' rows
    },
  ],

  // just play around with this number between 0 and 6
  weekStartingIndex: 6,

  // return a { year: number, month: number, day: number } object
  getToday(gregorainTodayObject) {
    return gregorainTodayObject;
  },

  // return a native JavaScript date here
  toNativeDate(date) {
    return new Date(date.year, date.month - 1, date.day);
  },

  // return a number for date's month length
  getMonthLength(date) {
    return new Date(date.year, date.month, 0).getDate();
  },

  // return a transformed digit to your locale
  transformDigit(digit) {
    return digit;
  },

  // texts in the date picker
  nextMonth: 'Next Month',
  previousMonth: 'Previous Month',
  openMonthSelector: 'Open Month Selector',
  openYearSelector: 'Open Year Selector',
  closeMonthSelector: 'Close Month Selector',
  closeYearSelector: 'Close Year Selector',
  defaultPlaceholder: 'Select...',

  // for input range value
  from: 'from',
  to: 'to',


  // used for input value when multi dates are selected
  digitSeparator: ',',

  // if your provide -2 for example, year will be 2 digited
  yearLetterSkip: 0,

  // is your language rtl or ltr?
  isRtl: false,
}

export const SIDELINKS = [
  {
    id: 1,
    link: '/wunschplanung',
    icon: "../icons/icon-01.svg",
    active: "../icons/icon-01-active.svg",
    label: "Wunschplan"
  },
  {
    id: 2,
    link: '/finaleplanung',
    icon: "../icons/icon-02.svg",
    active: "../icons/icon-02-active.svg",
    label: "Finale Planung"
  },
  {
    id: 3,
    link: '/mitarbeiter',
    icon: "../icons/icon-03.svg",
    active: "../icons/icon-03-active.svg",
    label: "Mitarbeiter"
  },
  {
    id: 4,
    link: '/einstellungen',
    icon: "../icons/icon-04.svg",
    active: "../icons/icon-04-active.svg",
    label: "Einstellungen"
  },
]