// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import WunschplanungPage from './pages/WunschplanungPage';
import FinalePlanungPage from './pages/FinalePlanungPage';
import MitarbeiterPage from './pages/MitarbeiterPage';


import './css/main.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='/wunschplanung/*' element={<WunschplanungPage />} />
        <Route path='/finaleplanung/*' element={<FinalePlanungPage />} />
        <Route path='/mitarbeiter' element={<MitarbeiterPage />} />
      </Routes>
    </Router>
  );
}

export default App;
