export const getCurrentDate = () => {
    const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const currentDate = new Date();
    const weekDay = days[currentDate.getDay()];
    const date = currentDate.toISOString().split('T')[0];
    const year = currentDate.getFullYear()
    return { weekDay, date, year };
}

export function formatDate(dateObject, format = 'default') {
    const { day, month, year } = dateObject;

    const date = new Date(year, month - 1, day); // Month in JavaScript Date object is 0-indexed

    if (format === 'default') {

        const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('de-DE', options);

        // Zerlegen des formatierten Datums, um den Wochentag und das Datum zu extrahieren
        const [weekDay] = formattedDate.split(', ');
        return { weekDay, date: `${year}-${month}-${day}`, day: day, month: month, year: year };
    } else if (format === 'custom') {
        const parts = dateObject.split('-');
        const year = parts[0];
        const month = parts[1].length === 1 ? '0' + parts[1] : parts[1];
        const day = parts[2].length === 1 ? '0' + parts[2] : parts[2];

        return `${day}.${month}.${year}`;
    } else {
        throw new Error('Ungültiges Format angegeben');

    }
}

