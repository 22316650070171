import React, { useState, useEffect, useRef, useReducer } from 'react';
import { SideNav } from '../components/SideNav';
import { CustomCalendar } from '../components/Calendar';
import { PrimarySmBtn, PrimaryMdBtn } from '../components/Buttons';
import { useLocation } from "react-router-dom";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import GroupsOverview from '../components/GroupsOverview';
import RosterList from '../components/RosterList';
import { myCustomLocale } from '../constants';
import { getCurrentDate, formatDate } from '../helper';
import { getApsEmployeesByWeekdayApi, getEmployeesCommitmentsByDateAndGroupIdApi, getApsEmployeesByYearApi } from '../api';



function FinalePlanungPage() {

    const [externalCalendarDate, setExternalCalendarDate] = useState(null);
    const [isDateSelected, setIsDateSelected] = useState(false);

    const [selectedDate, setSelectedDate] = useState(getCurrentDate());

    const [selectedYear, setSelectedYear] = useState(getCurrentDate().year);


    const [clickbtn, setClickbtn] = React.useState(false)

    const [selectedDay, setSelectedDay] = useState(null);


    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref} // necessary
            placeholder="Datum auswählen"
            value={selectedDay ? `${selectedDay.day}.${selectedDay.month}.${selectedDay.year}` : ''}
            style={{
                textAlign: 'center',
                padding: '1rem 1.5rem',
                fontSize: '1.5rem',
                border: '2px solid #3288D2',
                borderRadius: '0.7rem',
                color: '#282F79',
                outline: 'none',
                fontWeight: 'bold',
                cursor: 'pointer'
            }}
            className="my-custom-input-class" // a styling class
        />
    )

    const [apsEmployeesByWeekday, setApsEmployeesByWeekday] = useState([]);

    const [employeesCommitmentsByDateAndGroupId, setEmployeesCommitmentsByDateAndGroupId] = useState([]);
    const [clickedItemIndex, setClickedItemIndex] = useState(null);
    const [showingTime, setShowingTime] = useState(false);



    const toggleTimeDisplay = (index, item) => {
        if (clickedItemIndex === index) {
            setClickedItemIndex(null); // Setzt den Zustand auf null zurück, wenn das gleiche Element erneut geklickt wird
            setShowingTime(false);

        } else {
            setClickedItemIndex(index); // Setzt den Zustand auf den Index des geklickten Elements
        }
    };



    const [groupSelected, setGroupSelected] = useState(() => {
        // Überprüfe, ob ein Wert im lokalen Speicher vorhanden ist
        const storedGroupSelected = localStorage.getItem('groupSelected');
        // Wenn ein Wert vorhanden ist und dieser "true" ist, setze groupSelected auf true, ansonsten auf false
        return storedGroupSelected === 'true';
    });

    const [selectedGroupTitle, setSelectedGroupTitle] = useState(() => {
        // Lade den Wert von selectedGroupTitle aus dem lokalen Speicher
        return localStorage.getItem('selectedGroupTitle') || "";
    });

    const [selectedGroupId, setSelectedGroupId] = useState(() => {
        // Lade den Wert von selectedGroupTitle aus dem lokalen Speicher
        return localStorage.getItem('selectedGroupId') || "";
    });
    const location = useLocation();


    const handleGroupSelect = (title, id) => {
        setGroupSelected(true);
        setSelectedGroupTitle(title)
        setSelectedGroupId(id)
        localStorage.setItem('groupSelected', 'true');
        localStorage.setItem('selectedGroupTitle', title);
        localStorage.setItem('selectedGroupId', id);

    };

    const handleDeselect = () => {
        setGroupSelected(false);
        setSelectedGroupTitle("");
        setSelectedGroupId("");
        localStorage.removeItem('groupSelected');
        localStorage.removeItem('selectedGroupTitle');
        localStorage.removeItem('selectedGroupId');
    };


    const getApsEmployeesByWeekday = async (weekday, date, groupId) => {
        try {
            const data = await getApsEmployeesByWeekdayApi(weekday, date, groupId);
            if (data) {
                setApsEmployeesByWeekday(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [apsEmployeesByYear, setApsEmployeesByYear] = useState([]);


    const getApsEmployeesByYear = async (year, groupId) => {
        const getAllDaysOfYear = (year) => {
            const days = [];
            for (let month = 0; month < 12; month++) {
                const daysInMonth = new Date(year, month + 1, 0).getDate();
                for (let day = 1; day <= daysInMonth; day++) {
                    days.push(new Date(year, month, day));
                }
            }
            return days;
        };

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const getMonthInGerman = (month) => {
            const months = [
                'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
            ];
            return months[month];
        };

        const getWeekdayInGerman = (weekday, abbreviation) => {
            if (abbreviation) {
                const weekdays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
                return weekdays[weekday];
            } else {
                const weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
                return weekdays[weekday];
            }
        };

        const getCellStatusColor = (entries) => {
            const hasRedColor = entries.some(entry => entry.color === "#F83333");
            const hasOrangeColor = entries.every(entry => entry.color === "#F8C333");
            const hasGreenColor = entries.every(entry => entry.color === "#4AF833");

            return hasGreenColor ? "green" : (hasOrangeColor ? "orange" : (hasRedColor ? "red" : "red"));
        };

        const allDays = getAllDaysOfYear(year);

        try {
            const weekdayData = await getApsEmployeesByYearApi(year, groupId);

            const data = allDays.map((date) => {
                const weekday = date.getDay(); // Sonntag - Samstag: 0 - 6
                const germanWeekday = getWeekdayInGerman(weekday, true);
                const month = getMonthInGerman(date.getMonth());
                const monthKey = date.getMonth() + 1;
                const day = date.getDate();
                const formattedDate = formatDate(date);

                const dayData = weekdayData.filter(entry => formatDate(new Date(entry.date)) === formattedDate);
                const cellColor = dayData.length ? getCellStatusColor(dayData) : "red";

                return {
                    month,
                    monthKey,
                    day,
                    weekday: germanWeekday,
                    cellColor,
                    status: '' // Since we no longer fetch commitments, we don't set a status here
                };
            });

            // Organize data by month
            const organizedData = data.reduce((acc, { month, monthKey, day, weekday, cellColor, status }) => {
                let monthObj = acc.find(m => m.month === month);
                if (!monthObj) {
                    monthObj = { month, monthKey, days: [] };
                    acc.push(monthObj);
                }
                monthObj.days.push({ day, weekday, cellColor, status });
                return acc;
            }, []);

            console.log(organizedData);

            return organizedData;
        } catch (error) {
            console.error("Error fetching data:", error);
            return {};
        }
    };

    const hasLoggedRef = useRef(false);



    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const data = await getApsEmployeesByYear(selectedYear, selectedGroupId);
            if (isMounted) {
                setApsEmployeesByYear(data);
                if (!hasLoggedRef.current) {
                    hasLoggedRef.current = true;
                }
            }
        };

        if (groupSelected === true) {
            fetchData();
        }

        return () => {
            isMounted = false;
        };
    }, [selectedGroupId, selectedYear]);


    const getEmployeesCommitmentsByDateAndGroupId = async (date, groupId) => {
        try {
            const data = await getEmployeesCommitmentsByDateAndGroupIdApi(date, groupId);
            if (data) {
                setEmployeesCommitmentsByDateAndGroupId(data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };




    useEffect(() => {
        if (selectedDate && selectedDate.date != null && groupSelected === true) {
            getApsEmployeesByWeekday(selectedDate.weekDay, selectedDate.date, selectedGroupId);
            getEmployeesCommitmentsByDateAndGroupId(selectedDate.date, selectedGroupId)
        }

        if (externalCalendarDate !== null && selectedYear !== externalCalendarDate.year) {
            getApsEmployeesByYear(externalCalendarDate.year, selectedGroupId)
            setSelectedYear(externalCalendarDate.year)
        }

    }, [selectedDate, externalCalendarDate]);

    useEffect(() => {
        const handlePopstate = () => {
            // Überprüfe, ob der Benutzer über den Browser zurückgeht
            if (!groupSelected) {
                handleDeselect();
            }
        };

        window.onpopstate = handlePopstate;

        return () => {
            window.onpopstate = null;
        };
    }, [groupSelected]);

    useEffect(() => {
        // Überprüfe, ob der Benutzer auf die Seite zurückkehrt, auf der keine Gruppe ausgewählt ist
        if (groupSelected && location.pathname === "/finaleplanung") {
            handleDeselect();
        }
    }, [location.pathname, groupSelected]);



    const isMessageLogged = useRef(false)

    useEffect(() => {
        if (!isMessageLogged.current && apsEmployeesByYear.length > 0) {
            isMessageLogged.current = true;
        }
    }, [apsEmployeesByYear]);

    return (
        <div>
            {groupSelected === false ? (
                <GroupsOverview onGroupSelect={handleGroupSelect} />
            ) : (
                <div className='dashboard'>
                    <SideNav />

                    <section className='wunschplanung-cont'>
                        <div style={{ display: 'flex' }}>
                            <h2 className='title' style={{ flexGrow: 0.8, flexBasis: '1rem' }}>Finale Planung: {selectedGroupTitle}</h2>
                            <DatePicker
                                value={selectedDay}
                                onChange={date => {
                                    setSelectedDay(date);
                                    setExternalCalendarDate(date)
                                    const element = document.getElementById(`month-${date.month}`);
                                    if (element) {
                                        element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'auto' }); // Alternativ kannst du 'smooth' durch 'auto' ersetzen
                                    }
                                }}
                                inputPlaceholder="Datum auswählen"
                                locale={myCustomLocale}
                                renderInput={renderCustomInput} // render a custom input
                                shouldHighlightWeekends
                                colorPrimary="#3288D2"
                            />
                            <div style={{ flexGrow: 1, flexBasis: '1rem', display: 'flex', marginLeft: '4rem' }}>
                                <PrimaryMdBtn label='Excel exportieren' style={{ flexGrow: 1, flexBasis: '1rem', marginLeft: '1.4rem' }} />
                            </div>
                        </div>
                        <div style={{ flexGrow: 1, display: 'flex', height: '1rem' }}>
                            <div className='scroll-sm' style={{ flexGrow: 1, flexBasis: '1rem', paddingRight: '5.6rem', marginRight: '2rem', overflow: 'auto' }}>
                                <CustomCalendar
                                    calendarType={'finalPlaning'}
                                    isDateSelected={isDateSelected}
                                    setIsDateSelected={setIsDateSelected}
                                    selectedYear={selectedYear}
                                    data={apsEmployeesByYear}
                                    selectedDate={selectedDate}
                                    setSelectedDate={(newDate) => setSelectedDate(formatDate(newDate))}
                                    externalCalendarDate={externalCalendarDate}
                                />

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexBasis: '1rem', borderLeft: '.1rem solid #E2E2EA', padding: '0 2rem' }}>
                                {
                                    isDateSelected === false
                                        ?
                                        <div style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <p className='select-date-label'>Bitte wähle einen Tag aus, um die Detailansicht zu öffnen.</p>
                                        </div>
                                        :
                                        <>
                                            <div style={{ marginTop: '4.8rem', marginLeft: '1.6rem', marginRight: '3.2rem', display: 'flex', flexDirection: 'column' }}>
                                                {/* dev_fix */}
                                                <RosterList data={apsEmployeesByWeekday} />
                                            </div>

                                            <div className='scroll-sm' style={{ flexGrow: 1, overflow: 'auto', paddingRight: '4.2rem', paddingLeft: '4.2rem', marginTop: '2rem' }}>
                                                <p className='select-date-label' style={{ textAlign: 'left', marginBottom: '.4rem' }}>Folgende Kollegen haben sich an diesem Tag eingetragen:</p>

                                                {/* Employee List */}
                                                <div className='colleagues-list'>
                                                    {
                                                        employeesCommitmentsByDateAndGroupId.map((item, index) => (
                                                            <div className='colleagues-list__item' key={index} onClick={() => toggleTimeDisplay(index)}>

                                                                <div className='item-avatar'>
                                                                    {clickedItemIndex === index ? (
                                                                        <div style={{ fontSize: '12px', color: '#212124', textAlign: 'center', lineHeight: '1' }}>
                                                                            <p>{item.start_time}</p>
                                                                            <p>-</p>
                                                                            <p>{item.end_time}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div>{item.abbreviation}</div>
                                                                    )}
                                                                </div>

                                                                <p className='item-name'>{item.first_name}</p>
                                                            </div>
                                                        ))
                                                    }
                                                </div>

                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </section>
                </div>
            )}
        </div>

    )
}

export default FinalePlanungPage;